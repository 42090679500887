import React from "react";
import { useTranslation } from "react-i18next";
import { t as gt } from "i18next";

import { appURLs } from "@/app/app-urls.mjs";
import { FALLBACK_IMAGE_URL } from "@/app/constants.mjs";
import {
  ActiveSkills,
  PalInfoSidebar,
  SpawnMap,
} from "@/game-palworld/components/PalInfo.jsx";
import {
  WikiCard,
  WikiLayout,
} from "@/game-palworld/components/wiki.style.jsx";
import {
  getWikiData,
  useWikiData,
} from "@/game-palworld/utils/use-wiki-data.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

export const Pal = () => {
  const { t } = useTranslation();

  const {
    parameters: [palId],
  } = useRoute();
  const { pals } = useWikiData();

  if (!pals)
    return (
      <WikiLayout title={["palworld:pal", "Pal"]} imgSrc={FALLBACK_IMAGE_URL}>
        <div className="sidebar">
          <WikiCard loading style={{ height: "1500px" }} />
        </div>
        <div className="main">
          <SpawnMap loading />
          <WikiCard loading style={{ height: "1500px" }} />
          <WikiCard loading style={{ height: "128px" }} />
        </div>
      </WikiLayout>
    );

  const pal = pals[palId];
  if (!pal) return;

  return (
    <WikiLayout
      title={pal.label}
      imgSrc={`${appURLs.CDN_PLAIN}/${pal.imageUri}`}
    >
      <div className="sidebar">
        <PalInfoSidebar {...pal} />
      </div>
      <div className="main">
        <SpawnMap palInternalId={pal.internalId} />
        <ActiveSkills {...pal} />
        <WikiCard>
          <h3>{t("palworld:paldeckEntry", "Paldeck Entry")}</h3>
          <p>{t(...pal.paldeckEntry)}</p>
        </WikiCard>
      </div>
    </WikiLayout>
  );
};

export function meta([palId]) {
  const { pals, activeSkills } = getWikiData();
  const pal = pals?.[palId];

  const palName = pal ? gt(...pal.label) : gt("palworld:pal", "Pal");
  return {
    title: [
      "palworld:palSeo.title",
      "{{palName}} in Palworld: Complete Profile, Breeding & Strategy Guide | Blitz",
      { palName },
    ],
    description: pal
      ? [
          "palworld:palSeo.description",
          "Meet {{pal, t}}, the {{title, t}} in Palworld, exclusively on Blitz. Our detailed profile covers {{pal, t}}'s abilities, like {{ability1, t}} and {{ability2, t}} and offers expert breeding tips for rare traits and Pals. Unlock {{pal}}'s passive, {{passive}}, with our gameplay strategies. Explore {{pal, t}}'s work preferences and learn strategic gameplay techniques for both combat and crafting. Ideal for both novice adventurers and seasoned players, our guide helps you unlock the full potential of {{pal, t}}. Join us now and master the art of thriving with {{pal, t}} in your Palworld journey!",
          {
            pal: gt(...pal.label),
            title: gt(...pal.tagLine),
            passive: gt(...pal.partnerSkill.name),
            ability1: activeSkills
              ? gt(...activeSkills[pal.activeSkills[0].id].name)
              : "",
            ability2: activeSkills
              ? gt(...activeSkills[pal.activeSkills[1].id].name)
              : "",
          },
        ]
      : [
          "palworld:palSeo.fallback",
          "Meet {{palId}} in Palworld, exclusively on Blitz.",
          { palId },
        ],
  };
}

export default Pal;
